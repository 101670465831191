div.DashboardContent {
  display: flex;
}
div.DashboardContent > * {
  width: fit-content;
  margin: 20px;
}
div.modalContainer {
  padding: 20px;
  border-radius: 20px;
  width: fit-content;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}
